.contentToolbar {
  &__global-container {
    padding: 0.8rem;
    padding-top: 0;

    button {
      background: linear-gradient(
        270deg,
        rgba(223, 190, 106, 0.8),
        rgba(146, 111, 52, 0.8),
        rgba(34, 34, 34, 0),
        rgba(34, 34, 34, 0)
      );
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      background-position: 1% 50%;
      background-size: 300% 300%;
      letter-spacing: 1px;
      font-size: 11px;
      color: rgba(223, 190, 106, 0.7);
      transition: ease-in-out 0.5s;
      text-decoration: none;
      padding: 0.8rem;
      color: #fff;
      font-family: Helvetica, sans-serif;
      letter-spacing: 4px;
      text-transform: uppercase;
      cursor: pointer;
      border: 2px solid rgba(223, 190, 106, 0.3);

      &:hover {
        color: #fff;
        background-position: 99% 50%;
      }
    }

    &__top {
      margin-bottom: 0.8rem;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      display: inline-flex;
      background-color: #303030;
      border-radius: 5px;
      button {
        outline: none;
        box-shadow: none;
        border: none;
        padding: 0.4rem;
      }
    }

    &__bottom {
      display: flex;
      position: relative;
      align-items: center;
      p {
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        color: #fff;
      }
      button {
        margin-right: 1rem;
      }
      &-image-uploads {
        button {
          display: flex;
          align-items: center;
          svg {
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}

// Editor
.DraftEditor-root {
  blockquote {
    font-style: italic;
    margin-left: 20px;
    border-left: 4px solid #ddd;
    padding-left: 10px;
  }

  ul,
  ol {
    padding-left: 40px; /* Ajouter un retrait pour les listes */
  }

  ul {
    list-style-type: disc; /* Liste à puces */
  }

  ol {
    list-style-type: decimal; /* Liste numérotée */
  }
}

.indent {
  margin-left: 20px;
}

.decrease-indent {
  margin-left: 10px;
}

.editor-alignment-center div {
  text-align: center;
}
.editor-alignment-right div {
  text-align: right;
}

.editor-alignment-left div {
  text-align: left;
}

.image-container {
  position: relative;
  display: inline-block;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.image-container.removing {
  opacity: 0;
  transform: scale(0.7);
}

.notification {
  position: absolute;
  right: 0;
  color: #fff;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 0.8rem;
}

.error {
  background-color: red;
}

.success {
  background-color: green;
}

.loader {
  width: 22px;
  height: 22px;
  border: 5px solid #fff;
  border-bottom-color: rgba(223, 190, 106, 0.7);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  &__global-container {
    min-height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__global-container span {
    margin: auto;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-size: 20px;
    color: rgba(223, 190, 106, 0.7);
    background-position: 1% 50%;
    background-size: 300% 300%;
    font-family: Helvetica, sans-serif;
    padding: 0.8rem;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
  }

  @media screen and (max-width: 1024px) {
    &__global-container span {
      font-size: 1rem;
    }
  }
}

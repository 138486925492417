* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #101010;
}
/*
html::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;  Firefox 
  -ms-overflow-style: none;
}
*/


@media screen and (min-width: 1921px) {
  html {
    width: 1920px;
    margin: 0 auto;
  }
}

$primary-font: Arial, Helvetica, sans-serif;
$secondary-font: "Riesling";
$primary-color: #fff;
$secondary-color: rgba(223, 190, 106, 0.7);
$box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

.article {
  // Mobile
  &__wrapper {
    padding: 1rem;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2rem;
    h1 {
      letter-spacing: 4px;
      text-transform: uppercase;
      color: rgba(223, 190, 106, 0.7);
      text-align: center;
      font-family: Helvetica, sans-serif;
      font-size: 1.2rem;
      padding: 0.8rem;
      border: none;
      border: 1px solid rgba(223, 190, 106, 0.3);
      margin-bottom: 3rem;
    }
    p {
      font-size: 1.3rem;
      margin-bottom: 2rem;
      text-align: start;
      a,
      strong {
        color: rgba(223, 190, 106);
      }
    }
    figure {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem auto;
      figcaption {
        font-size: 1rem;
        margin-top: 1rem;
        color: rgba(223, 190, 106, 0.7);
        font-weight: bolder;
      }
    }
    img {
      width: 100%;
      height: auto !important;
      box-shadow: 0px 7px 10px rgba(black, 0.5);
      border: 4px solid rgba(223, 190, 106, 0.3);
    }
    blockquote {
      background-color: #26262698;
      box-shadow: 0px 7px 10px rgba(black, 0.5);
      padding: 1rem;
      border: 1px solid rgba(223, 190, 106, 0.3);
    }
  }

  &__button {
    margin-left: 1rem;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-size: 11px;
    color: rgba(223, 190, 106, 0.7);
    background: linear-gradient(
      270deg,
      rgba(223, 190, 106, 0.8),
      rgba(146, 111, 52, 0.8),
      rgba(34, 34, 34, 0),
      rgba(34, 34, 34, 0)
    );
    background-position: 1% 50%;
    background-size: 300% 300%;
    font-family: Helvetica, sans-serif;
    padding: 0.8rem;
    cursor: pointer;
    border: none;
    border: 1px solid rgba(223, 190, 106, 0.3);
    transition: ease-in-out 0.5s;
    &:hover {
      color: #fff;
      background-position: 99% 50%;
    }
  }
  // Tablette
  @media screen and (min-width: 768px) {
    &__wrapper {
      img {
        width: 80%;
      }
    }
  }
  // Desktop
  @media screen and (min-width: 1200px) {
    &__global-container {
      padding: 1rem 20%;
    }

    &__wrapper {
      padding: 1rem;
      img {
        width: 50%;
      }
    }
  }
}

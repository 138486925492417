.files {
  &__global-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Permet aux images de passer à la ligne suivante si nécessaire */
    margin-top: 10px;

    .file-wrapper {
      position: relative;
      width: 100px;
      height: 100px;
      margin-right: 10px;
      margin-bottom: 10px;
      overflow: hidden; /* Pour que le bouton ne dépasse pas des limites du wrapper */
    }

    .remove-btn {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 0, 0, 0.214); /* Couleur rouge semi-transparente */
      border: none;
      color: white;
      cursor: pointer;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain; /* Garde les proportions d'origine et évite l'étirement */
      border: 1px solid #ccc;
      border-radius: 5px; /* Arrondit les bords des images */
    }

    .file-wrapper:hover .remove-btn {
      opacity: 1; /* Le bouton devient visible lors du survol */
    }
  }
}

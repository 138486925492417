.input {
  // Global Style
  &__global-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 0.8rem;
    border: none;
    outline: none;
    color: rgb(211, 207, 201);
    padding-top: 0;

    // Input
    input {
      color: inherit;
      background-color: transparent;
      padding: 10px;
      border: none;
      border-bottom: 2px solid #303030;
      border-spacing: 0 0;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    // Clear Button
    button {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      color: #a0884f;
      font-size: 15px;
      border-radius: 50%;
      font-weight: bold;
      cursor: pointer;
      padding: 0;
      opacity: 0;
      transition: ease-in-out 0.3s;
    }

    input:not(:placeholder-shown) ~ button {
      opacity: 1;
    }
  }
}

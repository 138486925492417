$primary-font: Arial, Helvetica, sans-serif;
$primary-color: #fff;
$secondary-color: rgba(223, 190, 106, 0.7);

.privacy {
  // Mobile
  &__global-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__wrapper {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    color: $primary-color;
    font-family: $primary-font;
    h1 {
      font-size: 2.5rem;
      color: $secondary-color;
      margin: 2rem 0;
    }
    div {
      padding: 1rem;
      border-radius: 10px;
      h2 {
        margin: 2rem 0;
        color: $secondary-color;
      }
      p {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        a {
          color: $secondary-color;
          text-decoration: none;
        }
      }
    }
  }
  // Tablette
  // Desktop
  @media screen and (min-width: 1200px) {
    &__wrapper {
      width: 1000px;
    }
  }
}

.contentEditor {
  &__global-container {
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    border: none;
    outline: none;
    color: rgb(211, 207, 201);
    padding-left: calc(0.8rem + 10px);
    padding-right: calc(0.8rem + 10px);
    padding-top: 0;
    position: relative;

    .DraftEditor-root {
      position: relative;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 20px;
      min-height: 300px;
      max-height: 300px; /* Limite la hauteur maximale à 365px */
      overflow-y: auto; /* Ajoute un défilement vertical si le contenu dépasse 365px */
      overflow-x: hidden;
      &:focus {
        outline: none; /* Enlève la bordure de focus par défaut */
        border-color: #007bff; /* Bordure lors du focus */
      }
    }
  }
}

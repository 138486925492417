.form {
  &__global-container {
    width: 80%;
    background-color: #212121;
    margin: 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  &__banner {
    background-color: #404040;
    padding: 0.8rem;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    p {
      color: #fff;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}

.notification {
  position: absolute;
}
